const env = {
  phrase: {
    token: 'c4fd1f41883f925e56a3f3fbf37b874f28029194710bcd7d586a3e78add88bd7',
    projectId: 'b95909fab887a30c994c7449151ce557',
    localeIds: {
      en: 'd875a7f7f4c7e7688c1cb1961339127c',
      ca: 'e07cb9c73a86265136526313a52fa170',
      de: 'a3fc59ae4ccb09fb148a88f9eb1be116',
      el: 'ee416340a121e40a672491d91e471fb7',
      es: '1f1a28c7b4f935375b52e3d8f1c2959c',
      fr: 'e3152f3878223264a5c3e40f639b0064',
      it: 'e48ea86f687ab60baf49b81b7514f3b8',
      ja: '9897cd3d69616a310554f86b8be4ac46',
      nl: '2ff0385cd28e860981e0f0f3b18052c5',
      pl: 'ba454c595fdbc0d6267ed37df94440bb',
      pt: 'a5aeafdc2da7e9d7d756b8a96ac9e13d',
    },
  },
  socketUrl: 'wss://737mspryph.execute-api.eu-west-3.amazonaws.com/dev',
  profilePicture: {
    ratio: 10 / 16,
  },
};

const prod = {
  ...env,
  apiUrl: 'https://api.bearwww.com/api',
  socketUrl: 'wss://bgsqpk824b.execute-api.eu-west-3.amazonaws.com/prod',
  payboxUrl: {
    classic: {
      primary: "https://tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi",
      secondary: "https://tpeweb1.paybox.com/cgi/MYchoix_pagepaiement.cgi",
    },
    responsive: {
      primary: "https://tpeweb.paybox.com/cgi/FramepagepaiementRWD.cgi",
      secondary: "https://tpeweb1.paybox.com/cgi/FramepagepaiementRWD.cgi",
    },
  },
  castle: {
    publishableKey: 'pk_agshj9Hrd4Wyi11phVhUMp5Eu6PAqfdW',
  },
  oneSignal: {
    appId: 'c69f55ed-b1d6-425d-b7b7-9211da92de7d',
    allowLocalHost: false,
  },
  cookieDomain: ".bearwww.com",
};

const oldProd = {
  ...env,
  apiUrl: 'https://api.dev.thegoodweather.com/api',
  socketUrl: 'wss://bgsqpk824b.execute-api.eu-west-3.amazonaws.com/dev',
  payboxUrl: {
    classic: {
      primary: "https://tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi",
      secondary: "https://tpeweb1.paybox.com/cgi/MYchoix_pagepaiement.cgi",
    },
    responsive: {
      primary: "https://tpeweb.paybox.com/cgi/FramepagepaiementRWD.cgi",
      secondary: "https://tpeweb1.paybox.com/cgi/FramepagepaiementRWD.cgi",
    },
  },
  castle: {
    publishableKey: 'pk_agshj9Hrd4Wyi11phVhUMp5Eu6PAqfdW',
  },
  oneSignal: {
    appId: 'c69f55ed-b1d6-425d-b7b7-9211da92de7d',
    allowLocalHost: false,
  },
  cookieDomain: ".bearwww.com",
};

const staging = {
  ...env,
  apiUrl: 'https://api.dev.thegoodweather.com/api',
  socketUrl: 'wss://bgsqpk824b.execute-api.eu-west-3.amazonaws.com/dev',
  payboxUrl: {
    classic: {
      primary: "https://tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi",
      secondary: "https://tpeweb1.paybox.com/cgi/MYchoix_pagepaiement.cgi",
    },
    responsive: {
      primary: "https://tpeweb.paybox.com/cgi/FramepagepaiementRWD.cgi",
      secondary: "https://tpeweb1.paybox.com/cgi/FramepagepaiementRWD.cgi",
    },
  },
  castle: {
    publishableKey: 'pk_agshj9Hrd4Wyi11phVhUMp5Eu6PAqfdW',
  },
  oneSignal: {
    appId: 'c69f55ed-b1d6-425d-b7b7-9211da92de7d',
    allowLocalHost: false,
  },
  cookieDomain: ".bearwww.com",
  robotsContent: "# https://www.robotstxt.org/robotstxt.html\nUser-agent: *\nDisallow:",
};


const integration = {
  ...env,
  apiUrl: 'https://api.test.bearwww.com/api',
  socketUrl: 'wss://bgsqpk824b.execute-api.eu-west-3.amazonaws.com/test',
  payboxUrl: {
    classic: {
      primary: "https://preprod-tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi",
      secondary: "https://preprod-tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi",
    },
    responsive: {
      primary: "https://preprod-tpeweb.paybox.com/cgi/FramepagepaiementRWD.cgi",
      secondary: "https://preprod-tpeweb.paybox.com/cgi/FramepagepaiementRWD.cgi",
    },
  },
  castle: {
    publishableKey: 'pk_agshj9Hrd4Wyi11phVhUMp5Eu6PAqfdW',
  },
  oneSignal: {
    appId: 'cc8dda4d-060a-4687-9cfb-7be7f0bcc38e',
    allowLocalHost: false,
  },
  cookieDomain: ".test.bearwww.com",
};

const dev = {
  ...env,
  apiUrl: 'http://localhost:3000/api',
  socketUrl: 'wss://bgsqpk824b.execute-api.eu-west-3.amazonaws.com/test',
  payboxUrl: {
    classic: {
      primary: "https://preprod-tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi",
      secondary: "https://preprod-tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi",
    },
    responsive: {
      primary: "https://preprod-tpeweb.paybox.com/cgi/FramepagepaiementRWD.cgi",
      secondary: "https://preprod-tpeweb.paybox.com/cgi/FramepagepaiementRWD.cgi",
    },
  },
  castle: {
    publishableKey: 'pk_agshj9Hrd4Wyi11phVhUMp5Eu6PAqfdW',
  },
  oneSignal: {
    appId: '2ed32d5f-8d45-4b03-98cc-d9e96fe7297c',
    allowLocalHost: true,
  },
  cookieDomain: ".localhost",
};

// export const environment =
//   process.env.NODE_ENV === 'development'
//     ? dev
//     : window.location.host === 'd13s4g3q9ouuo3.cloudfront.net'
//     ? staging
//     : prod;

export let isLocalOrIntegrationEnv: boolean
export let environment;
if (process.env.NODE_ENV === 'development') {
  environment = dev;
  isLocalOrIntegrationEnv = true;
} else if (window.location.host === 'www.test.bearwww.com') {
  // tried custom var defined in integration.env
  // like in https://create-react-app.dev/docs/adding-custom-environment-variables/
  // but doesn't seem to work, so I copied staging
  environment = integration;
  isLocalOrIntegrationEnv = true;
} else if (window.location.host === 'd13s4g3q9ouuo3.cloudfront.net') {
  environment = staging;
  isLocalOrIntegrationEnv = true;
} else {
  environment = prod;
  isLocalOrIntegrationEnv = false;
}
